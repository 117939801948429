<template>
    <div class="footer">
        <div class="footer-logo">
            <img style="width: 119px; height: 75px;" src="@/assets/new_home_slices/KNET_logo.png" alt="">
            <div style="margin-top: 29px;" class="footer-logo-text">Your all-in-one</div>
            <div class="footer-logo-text">cross-listing platform</div>
            <div class="footer-logo-apps">
                <img class="footer-logo-apps-img" src="@/assets/new_home_slices/discord.png" alt="" @click="open('https://discord.gg/ZdnnJXzk5F')">
                <img class="footer-logo-apps-img" src="@/assets/new_home_slices/ins.png" alt="" @click="open('https://www.instagram.com/knetgroup/')">
            </div>
        </div>
        <div class="footer-menu">
            <div class="footer-menu-info">
                <div class="footer-menu-info-title">INFORMATION</div>
                <div class="footer-menu-info-item" @click="sendEmail">Contact Us</div>
                <div class="footer-menu-info-item" @click="$router.push('/about')">About Us</div>
            </div>
            <div class="footer-menu-info">
                <div class="footer-menu-info-title">LEGAL</div>
                <div class="footer-menu-info-item" @click="$router.push('/policy')">Privacy Policy</div>
                <div class="footer-menu-info-item" @click="$router.push('/faq')">FAQ</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterPage',
    data() {
        return {
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        open (url) {
            window.open(url, '_blank')
        },
        sendEmail () {
            const email = 'support@knetgroup.com';
            window.location.href = `mailto:${email}`;
        },
        goHomeTop () {
            if (this.$route.name !== 'index') {
                this.$router.replace('/')
            }
            window.scrollTo({top: 0})
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    box-sizing: border-box;
    width: 100%;
    background: #00DD3E;
    padding: 59px 0 59px 30px;
    display: flex;
    .footer-logo {
        flex: 1;
        .footer-logo-text {
            font-family: GramatikaTrial;
            font-weight: 700;
            font-size: 22px;
            line-height: 26.4px;
            color: #00000080;
        }
        .footer-logo-apps {
            margin-top: 21px;
            display: flex;
            gap: 12px;
            .footer-logo-apps-img {
                width: 36px;
                height: 36px;
            }
        }
    }
    .footer-menu {
        flex: 1;
        display: flex;
        .footer-menu-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 36px;
            .footer-menu-info-title {
                font-family: GramatikaTrial-Bold;
                font-weight: 700;
                font-size: 24px;
                line-height: 28.8px;
                color: #000000;
            }
            .footer-menu-info-item {
                font-family: GramatikaTrial;
                font-weight: 700;
                font-size: 22px;
                line-height: 26.4px;
                color: #00000080;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 576px) {
    .footer {
        box-sizing: border-box;
        background: #00DD3E;
        padding: 39px 0 48px 30px;
        display: flex;
        .footer-logo {
            flex: 1;
            .footer-logo-text {
                font-family: GramatikaTrial;
                font-weight: 700;
                font-size: 12px;
                line-height: 14.4px;
                color: #00000080;
            }
            .footer-logo-apps {
                margin-top: 26px;
                display: flex;
                gap: 10px;
                .footer-logo-apps-img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .footer-menu {
            flex: 2;
            display: flex;
            .footer-menu-info {
                flex: 1;
                box-sizing: border-box;
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                gap: 43px;
                .footer-menu-info-title {
                    font-family: GramatikaTrial-Bold;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16.8px;
                    color: #000000;
                }
                .footer-menu-info-item {
                    font-family: GramatikaTrial;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16.8px;
                    color: #00000080;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>